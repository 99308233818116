import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';
import { DataLocal } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DataLocalService {

  // Declare a variable named '_storage' and 'user'
  private _storage: Storage | null = null;
  user: DataLocal[] = [];

  constructor(private storage: Storage) { 
    this.init();
  }

  // This function is used to initialize and create the LocalStorage in the browser and load its information  

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;

    this.cargarDadesUsuari();
  }

  // This function allows us to load the information from the localstorage and if there is nothing we initialize it

  async cargarDadesUsuari() {
    const user = await this.storage.get('usuari');
    this.user = user || [];
    return this.user;
  }

  /**
   * This function allows us to store user data when we log in to the application
   * @param  {[string]} user User data
  */

  saveUserData(response) {
    this.storage.set('usuari', response);
    return true;
  }

  // This allow us to clear all LocalStorage

  clear() {
    this.storage.clear().then(() => {
      window.location.reload();
    });
  }

  // This allow us to get all data from LocalStorage named 'usuari'

  async getUser() {
    let user:any = await this.storage.get('usuari');
    return user;
  }
}
