import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../environments/environment';

import { throwError } from 'rxjs';

import { of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataLocalService } from './data-local.service';
import { SharedFunctionsService } from './shared-functions.service';

const URL = environment.url;

@Injectable({
  providedIn: 'root',
})

export class ConnectionService {

//Declare some variables

private now = '00/00/0000';
private tz = '';

private httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'token': '',
    'id': '',
    'time': ''
  }),
};

  constructor(private http: HttpClient, private _storage: DataLocalService, private _sha: SharedFunctionsService) {}
  
 // Updates for the headers

  updateTime() {
    let ahora = new Date();
    this.now = ahora.toISOString();
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // Header for private content (header for intern app, when the user is logged in)
  async updatePrivateHeaders() {
    let user:any;
    user = await this._storage.getUser();

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'token': user.token,
        'id': user.id.toString(),
        'time': this.now,
        'timezone': this.tz,
        'browser': this._sha.myBrowser()
      }),
    };
  }

  // Header for public content (header for external app, when the user is not logged in)
  updatePublicHeaders() {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'time': this.now,
        'timezone': this.tz,
        'browser': this._sha.myBrowser()
      })
    }
    return options;
  }

  // Header for private content (header for private app, when the user is logged in and we need the response of a media content)
  async updateMediaHeaders(){
    const user = await this._storage.getUser();
    const options = {
      headers: new HttpHeaders({
        'token': user.token,
        'id': user.id.toString(),
        'time': this.now,
        'timezone': this.tz,
        'browser': this._sha.myBrowser()
      })
    }
    return options;
  }

  //Methods of each service

  //1. Methods of public service

    //1.1 Register API response
    
  register(params) {
    this.updateTime();
    const head = this.updatePublicHeaders();

    return this.http
      .post(URL + `register`, params, head)
      .pipe(catchError(this.handleError));
  }

    //1.2 Login API response

  login(params) {
    this.updateTime();
    const head = this.updatePublicHeaders();

    return this.http
      .post(URL + `login`, params, head)
      .pipe(catchError(this.handleError));
  }

    //1.3 Pass forgot sending an email

  passForgot(params) {
    this.updateTime();
    const head = this.updatePublicHeaders();

    return this.http
      .put(URL + `pass/forgot`, params, head)
      .pipe(catchError(this.handleError));
  }

    //1.4 This method we need to recovery and change the user password

  changePass(params) {
    this.updateTime();
    const head = this.updatePublicHeaders();

    return this.http
      .put(URL + `pass/recovery`, params, head)
      .pipe(catchError(this.handleError));
  }

    //1.5 This method we need to verify email and send the token to do it

  verifyEmail(params) {
    this.updateTime();
    const head = this.updatePublicHeaders();

    return this.http.put(URL + `verifyEmail`, params, head).pipe(catchError(this.handleError));
  }

    //1.6 We need this method, to send verification email if the user is not validated when it tries to log in

  sendEmail(params) {
    this.updateTime();
    const head = this.updatePublicHeaders();

    return this.http.put(URL + `sendEmail`, params, head).pipe(catchError(this.handleError));
  }


  // 2. Auxiliary methods for user or company information --> Gets of communities, provinces and postal code

  async getCommunities() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `communities`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  async getProvinces(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `provinces/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  async getPostal(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `community/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 3. Company methods

    // 3.1 Method for displaying company information

  async getCompany() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `profile/company`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 3.2 Method for updating company information

  async updateCompany(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `profile/company`, params, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // 4. Profile methods

    // 4.1 Method for displaying user profile information

  async getProfile() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `profile/user`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 4.2 Methods for updating user profile information

  async updateProfile(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `profile/user`, params, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

    // 4.3 Methods for updating user profile avatar

  async uploadFile(params){
    this.updateTime();
    const head = await this.updateMediaHeaders();

    return this.http.put(URL + `upload/image`, params, head)
    .pipe(
      catchError(this.handleError)
    );
  }

    // 4.4 Methods for updating user password

  async updatePassword(params) {
    this.updateTime();
    await this.updatePrivateHeaders();
    
    return this.http
      .put(URL + `profile/password`, params, this.httpOptions)
      .pipe(catchError(this.handleError));
  }

  // 5. CRUD Usuaris methods

    // 5.1 Method for create a user

  async createUser(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `users/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.2 Method for list all company users with pagination

  async loadPageUser(page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}users?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  async loadFilterPageUser(page, limit, option, search) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}users?page=${page}&limit=${limit}&type=${option}&search=${search}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.3 Method for show selected user information

  async showUser(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `users/` + params + `/show` , this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.4 Method for update selected user information

  async updateUser(params)  {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `users/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.5 Method for delete selected user

  async deleteUser(params)  {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .delete(URL + `users/delete/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.6 Method for update selected user password

  async updateUserPassword(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `users/update/pass`, params, this.httpOptions)
      .pipe(catchError(this.handleError));
  }


    // 5.7 Auxiliar method to get all user roles we have in the database

  async getRoleUser() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `users/roles`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.8 Auxiliar method to get all user states we have in the database

  async getStateUser() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `users/states`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.9 Method to get all assigned  user projects with pagination
  async getProjectUser(id, page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}users/${id}/projects?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.10 Method to create a assigned project to the selected user
  async createUserProject(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `users/projects/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.11 Method to get all projects that can be assigned to the selected user

  async getProjectUserAssign() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}users/projects`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 5.12 Method to get the URL to download the generated file (CSV or PDF)

  async generateCSVTime(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `time/csv`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 6. CRUD Projects

    // 6.1 Method to create a project

  async createProject(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `projects/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 6.2 Method to get all company projects with pagination

  async loadPageProject(page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}projects?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 6.3 Method to show the selected project information

  async showProject(params) {
    this.updateTime();
    await this.updatePrivateHeaders();
    
    return this.http
      .get(URL + `projects/` + params + `/show` , this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 6.4 Method to update the selected project information

  async updateProject(params)  {
    this.updateTime();
    await this.updatePrivateHeaders();
  
    return this.http
      .put(URL + `projects/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 6.5 Method to delete the selected project

  async deleteProject(params)  {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .delete(URL + `projects/delete/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 6.6 Method to get all projects states to display in a drop-down list

   async getStateProject() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `projects/show/states`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 6.7 Method to obtain all the projects and tasks related to each project and if there is an active task it shows it to you

  async getProjectUserTime() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `projects/user/timer/`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 6.8 Method to obtain all user projects (used to role worker)
  
  async getProjectsUser(page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `projects/user?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 7. Kanbanflow

    // 7.1 Method to obtain all column that can be used in a project

  async getProjectColumn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `kanban` + params + `tasks`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 7.2 Method to create a project column

  async createProjectColumn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `kanban/add`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 7.3 Method to update a selected project column

  async updateProjectColumn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `kanban/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 7.4 Method to delete a selected project column

  async deleteProjectColumn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .delete(URL + `kanban/delete` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 7.5 Method to assign a user in a project

  async assignUserProject(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `projects/update/users/add`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 7.6 Method to remove a user in a project

  async removeUserProject(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `projects/update/users/remove`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 8. Tasks

    // 8.1 Method to get all project tasks with pagination

  async loadPageTaskUser(params, page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}projects/${params}/tasks?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.2 Method to show all project tasks with pagination

  async showTask(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `task/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.3 Method to get all projects columns (drop-down) that can be assigned in a task

  async getColumnProject(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `project/` + params + '/kanban', this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.4 Method to create a task in a project

  async createTask(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `task/add`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.5 Method to update the selected task

  async updateTask(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `task/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.6 Method to delete a task

  async deleteTask(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .delete(URL + `task/delete/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 8. Timer

    // 8.1 Method that is used to save all information about the timer (project, task and entry time...)

  async entryTime(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `time/entry`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.2 Method that is used to update the exit time in the active timer

  async exitTime(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `time/exit`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.3 Method to update the entry time in the active timer

  async entryUpdateTime(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `time/entry/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.4 Method to update the exit time in the active timer

  async exitUpdateTime(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `time/exit/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 8.5 Method to save the comment in the active timer

  async saveComment(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `time/comment/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 9. Holidays

    //  9.1 Holidays Options

      // 9.1.1 Method for list all holidays options with pagination

  async loadPageHolidayOptions(page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}holiday/options/pagination?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

      // 9.1.2 Method for list all holidays options without pagination (we need this to a drop-down)

  async getHolidayOptions() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}holiday/options`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 9.1.3 Method to show the information about the selected holiday option

  async showHolidayOptions(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `holiday/option/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 9.1.4 Method to create a new holiday option

  async createHolidayOptions(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `holiday/options/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 9.1.5 Method to update the selected holiday option

  async updateHolidayOptions(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `holiday/options/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 9.1.5 Method to delete the selected holiday option

  async deleteHolidayOptions(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .delete(URL + `holiday/options/delete/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  //9.2 Holidays Company

    // 9.2.1 Method to get the holidays of the company

  async getHolidayCompany() {
    this.updateTime();
    await this.updatePrivateHeaders();
    
    return this.http
      .get(URL + `holiday/company`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 9.2.2 Method to create a new holiday company

  async createHolidayCompany(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `holiday/company/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  //9.3 Holidays User

    // 9.3.1 Method to get the holidays of the logged user

  async getHolidayUser() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `holiday/show`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 9.3.2 Method to create a new holiday

  async createHolidayUser(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `holiday/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 10. Turns

    // 10.1 Method for list all turns with pagination
  async loadPageTurns(page, limit) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(`${URL}turns?page=${page}&limit=${limit}`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.2 Method for get all information about a selected turn

  async getInfoTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `turns/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.3 Method to show all selected user turn

  async showUserTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `turns/users/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.4 Method for get all turns that can be assigned in a user
  async getTurns() {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .get(URL + `turns/users`, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.5 Method to create a turn
  async createTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `turns/create`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.6 Method to edit a selected turn

  async editTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `turns/update`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.7 Method to delete a selected turn

  async deleteTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .delete(URL + `turns/delete/` + params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.8 Method to assign a user in a turn

  async assignUserTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .post(URL + `turns/users/add`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

    // 10.9 Method to remove a user in a assigned turn

  async removeUserTurn(params) {
    this.updateTime();
    await this.updatePrivateHeaders();

    return this.http
      .put(URL + `turns/users/remove`, params, this.httpOptions)
      .pipe(catchError(this.handleError)
    );
  }

  // 11. Handle d'error

  private handleError(error: HttpErrorResponse) {
    let finalError:any = {'code':0,'message':'','key':''};
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      finalError = error;
      console.error('An error occurred:');
      finalError.message = 'An error happens';
    } else {
      finalError = error;
      finalError.message = error.error;
      finalError.code = error.status;
      finalError.key = error.error.code;
      console.log('controled error:',finalError);
    }
      return throwError(finalError);
  }
}
