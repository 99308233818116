import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionControlDirective } from './permission-control.directive';

@NgModule({
  declarations: [PermissionControlDirective],
  imports: [
    CommonModule
  ],
  exports: [
    PermissionControlDirective
  ]
})
export class SharedDirectivesModule { }
