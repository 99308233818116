import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {

  private delete_user: any;
  modal: Subject<any> = new Subject<any>();

  constructor(private router:Router) { }

  // This function allows us to check what navigator uses this client
  myBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';

    }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        return 'Chrome';

    }else if(navigator.userAgent.indexOf("Safari") != -1){
        return 'Safari';

    }else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
         return 'Firefox';

    }else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document['documentMode'] == true )){
      return 'IE'; 

    } else {
       return 'Unknown';

    }
  }

    /**
     * This function allows us to pass values from the page to the component shared named modal
     * @param  {[Object]} values Data modal to delete
    */

  updateModal(values) {
    this.modal.next(values);
  }

  /**
    * This function allows us to create a dynamic page ranger, we need to do the actual page, the initial range and the final or end range
    * @param  {[number]} actual Actual range page the user is
    * @param  {[number]} range Initial range pagination we get from the list of values
    * @param  {[number]} end End range pagination we get from the list of values
  */
  
  pageRange(actual, range, end) { 
    var from = actual - range, 
    to = actual + range,
    pages = [];

    for(var i=1; i<=end; i++) {
      if(i===1 || i===end || (i >= from && i <= to)) { 
        pages.push(i);
      } 
    }
    return pages; 
  }

  /**
    * With this function we create the page ranger with ellipsis
    * @param  {[Array]} pages Array of pages we get in the function pageRange
  */
  
  pageEllipsis(pages) {
    let end = pages.pop();
    pages.push(end);
    let range_ellipsis = []; 
    for(var i=0; i<pages.length; i++) {
      if(pages[i-1]===1 && pages[i]!==2) { 
        range_ellipsis.push('...');
      }

      range_ellipsis.push(pages[i]);
      
      if(pages[i+1]===end && pages[i]!==(end-1)) { 
        range_ellipsis.push('...'); 
      } 
    }

    return range_ellipsis; 
  }
}


