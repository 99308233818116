import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'pass/forgot',
    loadChildren: () => import('./pages/pass/forgot-pass/forgot-pass.module').then( m => m.ForgotPassPageModule),
  },
  {
    path: 'pass/recovery',
    loadChildren: () => import('./pages/pass/recovery/recovery.module').then( m => m.RecoveryPageModule),
  },
  {
    path: 'verifyEmail',
    loadChildren: () => import('./pages/pass/verify/verify.module').then( m => m.VerifyPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'timer',
    loadChildren: () => import('./pages/timer/timer.module').then( m => m.TimerPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'projects',
    loadChildren: () => import('./pages/projects/projects.module').then( m => m.ProjectsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'holidays',
    loadChildren: () => import('./pages/holidays/holidays.module').then( m => m.HolidaysPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'turns',
    loadChildren: () => import('./pages/turns/turns.module').then( m => m.TurnsPageModule),
    canActivate: [AuthGuardService]
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
