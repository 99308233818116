import { Component, OnInit, Input } from '@angular/core';

import { SharedFunctionsService } from '../../services/shared-functions.service';
import { ConnectionService } from '../../services/connection.service';

declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {

  // This component is the modal component to delete all CRUDS we have. We have a switch depending what we want to delete.

  public user;

  control: boolean = false;

  modal: any = {
    open: false, 
    values: '',
    type: ''
  };

  error: boolean = false;
  works: boolean = false;
  error_message = '';
  works_message = '';

  constructor(private _sha:SharedFunctionsService, private _con:ConnectionService) { }

  // When we initialize this component we subscribe to the modal function we have in the SharedFunctionsService and we get all values from each value we want to delete
  ngOnInit() {
    this._sha.modal.subscribe((value) => {
      this.modal = value;
      if (value && value.open === true) {
        this.openModal();
      } else {
        this.closeModal();
      }
    });

  }

  // Function used to open the modal using JQuery
  openModal() {
    $('#globalModal').modal('show');
  }

  // Function used to close the modal using JQuery
  closeModal() {
    $('#globalModal').modal('hide');
  }

  // Function with a switch depending the modal.type we can delete a determinate case is used when the user clicks the button named 'Delete'
  async sendModal() {
    //1. If the modal type has 'deleteuser' it means we want to delete a user.
    switch (this.modal.type) {
      case 'deleteuser':
        (await this._con.deleteUser(this.modal.values.id)).subscribe(
          (response: any) => {
            if(response) {
              this.works_message = response.msg;
              this.works = true;
              this.control = true;
            }

            //To reload the page
            if(this.control) {
              this.reload();
            }
          },
          (error_data: any) => {
            this.error_message = '';
            for (let i = 0; i < error_data.message.length; i++) {
              if (i != 0) {
                this.error_message += '\n';
              }
              this.error_message += error_data.message[i].msg;
            }
            this.error = true;
          }
        );
        $('#globalModal').modal('hide');
        break;

      //2. If the modal type has 'deleteproject' it means we want to delete a project.
      case 'deleteproject':
        (await this._con.deleteProject(this.modal.values.id)).subscribe(
          (response: any) => {
            if(response) {
              this.works_message = response.msg;
              this.works = true;
              this.control = true;
            }

            if(this.control) {
              this.reload();
            }
          },
          (error_data: any) => {
            this.error_message = '';
            for (let i = 0; i < error_data.message.length; i++) {
              if (i != 0) {
                this.error_message += '\n';
              }
              this.error_message += error_data.message[i].msg;
            }
            this.error = true;
          }
        );
        $('#globalModal').modal('hide');
        break;

      //3. If the modal type has 'deletetask' it means we want to delete a task.
      case 'deletetask':
        (await this._con.deleteTask(this.modal.values.id)).subscribe(
          (response: any) => {
            if(response) {
              this.works_message = response.msg;
              this.works = true;
              this.control = true;
            }

            if(this.control) {
              location.reload();
            }
          },
          (error_data: any) => {
            this.error_message = '';
            for (let i = 0; i < error_data.message.length; i++) {
              if (i != 0) {
                this.error_message += '\n';
              }
              this.error_message += error_data.message[i].msg;
            }
            this.error = true;
          }
        );
        $('#globalModal').modal('hide');
        break;

        //4. If the modal type has 'deleteholidayoptions' it means we want to delete a holiday option.
        case 'deleteholidayoptions':
          (await this._con.deleteHolidayOptions(this.modal.values.id)).subscribe(
            (response: any) => {
              if(response) {
                this.works_message = response.msg;
                this.works = true;
                this.control = true;
              }

              if(this.control) {
                this.reload();
              }
            },
            (error_data: any) => {
              this.error_message = '';
              for (let i = 0; i < error_data.message.length; i++) {
                if (i != 0) {
                  this.error_message += '\n';
                }
                this.error_message += error_data.message[i].msg;
              }
              this.error = true;
            }
          );
          $('#globalModal').modal('hide');
          break;

          //5. If the modal type has 'deleteholidayoptions' it means we want to delete a holiday option.
          case 'deleteturn':
          (await this._con.deleteTurn(this.modal.values.id)).subscribe(
            (response: any) => {
              if(response) {
                this.works_message = response.msg;
                this.works = true;
                this.control = true;
              }

              if(this.control) {
                this.reload();
              }
            },
            (error_data: any) => {
              this.error_message = '';
              for (let i = 0; i < error_data.message.length; i++) {
                if (i != 0) {
                  this.error_message += '\n';
                }
                this.error_message += error_data.message[i].msg;
              }
              this.error = true;
            }
          );
          $('#globalModal').modal('hide');
          break;

          //6. If the modal type has 'deleteassignproject' it means we want to delete a user assigned in a project.
          case 'deleteassignproject':
          (await this._con.removeUserProject({'id_project': this.modal.values.id, 'id_user': this.modal.user.id})).subscribe(
            (response: any) => {
              if(response) {
                this.works_message = response.msg;
                this.works = true;
                this.control = true;
              }

              if(this.control) {
                this.reload();
              }
            },
            (error_data: any) => {
              this.error_message = '';
              for (let i = 0; i < error_data.message.length; i++) {
                if (i != 0) {
                  this.error_message += '\n';
                }
                this.error_message += error_data.message[i].msg;
              }
              this.error = true;
            }
          );
          $('#globalModal').modal('hide');
          break;

          //7. If the modal type has 'deleteassignturn' it means we want to delete a user assigned in a turn.
          case 'deleteassignturn':
          (await this._con.removeUserTurn({'id_turn': this.modal.values.id, 'id_user': this.modal.user.id})).subscribe(
            (response: any) => {

              if(response) {
                this.works_message = response.msg;
                this.works = true;
                this.control = true;
              }

              if(this.control) {
                this.reload();
              }
            },
            (error_data: any) => {
              this.error_message = '';
              for (let i = 0; i < error_data.message.length; i++) {
                if (i != 0) {
                  this.error_message += '\n';
                }
                this.error_message += error_data.message[i].msg;
              }
              this.error = true;
            }
          );
          $('#globalModal').modal('hide');
          break;

      default:
        $('#globalModal').modal('hide');
        break;
    }
  }

  // To reload the page in 1500ms
  reload() {
    setTimeout(window.location.reload.bind(window.location), 1500);
  }
}