import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataLocalService } from './data-local.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  // Declare a variable 'routeURL' and 'user';
  routeURL;
  user;

  constructor(private _data: DataLocalService, private router: Router, private location: Location) {
    // Initialize 'routeURL' with current route URL
    this.routeURL = this.location.path();
  }
  
  // The Router call canActivate() method,
  // If canActivate is registered in Routes[]
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
   
    return new Promise(async (resolve, reject) => {

      // We check if we are logged or not if the Local Storage return any user data.
      await this._data.getUser().then((value) => {
        this.user = value;
      });

      // If user data is null and we are in a different route of '/'
      if (this.user === null && this.routeURL !== '/') {
          
        // Inject router and redirect to '/' where we have the login/register view
          this.router.navigate(['/']);
          return resolve(false);

      } else {
          // Re-assign current route URL to 'routeURL'
          // When the user is logged in
        this.routeURL = this.location.path();

          // Just return true - if user is logged in
        return resolve(true);
      }
    });
  }
}