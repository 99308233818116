import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  Injectable,
} from '@angular/core';
import { DataLocalService } from '../services/data-local.service';

@Directive({
  selector: '[PermissionControl]',
})
export class PermissionControlDirective {

  // So our use case is to create a directive, which shows/hides elements on the page based on our currently authenticated user's permissions.
  
  private roles: any[] = [];

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private _data: DataLocalService,
  ) {}

  @Input('PermissionControl') selectedPermission: string;

  public async ngOnInit(): Promise<void> {
    let user = await this._data.getUser();
    this.roles.push(user.role);

    if (!this.roles) {
      // Remove element from DOM
      this.viewContainerRef.clear();
    }

    // User Role are checked by a Roles mention in DOM
    const idx = this.roles.findIndex(
      (element) => this.selectedPermission.indexOf(element) !== -1
    );

    if (idx < 0) {
      this.viewContainerRef.clear();
    } else {
      // Appends the ref element to DOM
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
