import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataLocalService } from '../../services/data-local.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {

  // This component has the utility of logging out of all pages. You have to have the Component Module imported into the page module.
  constructor(private router: Router, private _data: DataLocalService) { }

  ngOnInit() {}

  logout() {
    //To clear the LocalStorage.
    this._data.clear();
    this.router.navigate(['/']);
  }
}
