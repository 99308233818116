import { Component, HostListener } from '@angular/core';
import { DataLocalService } from './services/data-local.service';
import { Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  user: any;
  roles: any;

  active = '';

  componentes: any = [
    { name: 'Home', redirectTo: '/home', icon: 'home-outline', permission: 'Worker, Project Manager, Administrator' },
    { name: 'Register', redirectTo: '/register', icon: 'log-in-outline', permission: '' },
    { name: 'Profile', redirectTo: '/profile', icon: 'person-circle-outline', permission: 'Worker, Project Manager, Administrator' },
    { name: 'Company', redirectTo: '/company', icon: 'information-outline', permission:'Administrator' },
    { name: 'Timer', redirectTo: '/timer', icon: 'hourglass-outline', permission:'Worker, Project Manager, Administrator' },
    { name: 'Project', redirectTo: '/projects', icon: 'cube-outline', permission:'Worker, Project Manager, Administrator' },
    { name: 'Users', redirectTo: '/users', icon: 'people-outline', permission:'Administrator' },
    { name: 'Holiday', redirectTo: '/holidays', icon: 'calendar-number-outline', permission: 'Worker, Project Manager, Administrator'},
    { name: 'Turn', redirectTo: '/turns', icon: 'time-outline', permission: 'Administrator'}
  ];

  constructor(private _data: DataLocalService, private router: Router) {
    this.getUser();
  }

  // With this we get the user information from Local Storage Service
  async getUser() {
    this.user = await this._data.getUser();
  };


  // If the user closes all pages and does not log out, we remove all local storage from the user's client.
  @HostListener('window:unload')
    private onUnload(): void {
        this._data.clear();
    }
}
